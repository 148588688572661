import { render, staticRenderFns } from "./AsideCard.vue?vue&type=template&id=71e6c186&scoped=true&"
import script from "./AsideCard.vue?vue&type=script&lang=js&"
export * from "./AsideCard.vue?vue&type=script&lang=js&"
import style0 from "./AsideCard.vue?vue&type=style&index=0&id=71e6c186&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71e6c186",
  null
  
)

export default component.exports