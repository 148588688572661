<template>
    <a href="#">
      <slot/>
    </a>
  </template>
  
  <script>
  export default {
    name: "DropdownItem"
  };
  </script>